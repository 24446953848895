import {mapState, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapState({
      themeName: 'themeName',
      isBt:"isBt",
      isLt:"isLt",
      isXbk:"isXbk",
      tenantKey:'tenantKey'
    }),
    ...mapGetters({
      theme: "theme"
    })
  }
}
